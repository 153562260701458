<template>
    <v-app>


        <v-card>
            <v-card-title >
                <div class="d-flex justify-space-around">
                    Students List <br>
                    Class : {{academic_class.title}} <br>
                    Semester/Year : {{academic_class.grade.title}} <br>
                    Program : {{academic_class.program.title}} <br>
                    Date : {{today}} <br>
                </div>



            </v-card-title>
            <v-card-text>
                <div class="row ">
                    <div class="col-12">
                        <div class="">
                            <table class="table table-stripe">
                                <thead>
                                <th>
                                    <v-checkbox @change="selectAll()" outlined dense ></v-checkbox>
                                </th>
                                <th>Symbol No.</th>
                                <th>Student name</th>
                                </thead>
                                <tbody>
                                <tr v-for="(student, index) of selectedStudents" :key="index">
                                    <!--                  <td>-->
                                    <!--                                          -->
                                    <!--                  </td>-->
                                    <td>
                                        <v-checkbox v-model="selected" :value="index" outlined
                                                    dense></v-checkbox>
                                    </td>
                                    <td>{{ student.setting ? student.setting.symbol_no : '-' }}</td>
                                    <td>{{ student.full_name }}</td>

                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                >
                    Close
                </v-btn>
                <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>


    </v-app>
</template>
<script>
    import vMultiselectListbox from 'vue-multiselect-listbox'
    import "vue-multiselect-listbox/src/scss/vue-multi-select-listbox.scss";
    import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
    import LevelService from "@/core/services/level/LevelService";
    import ProgramService from "@/core/services/level/program/ProgramService";
    import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
    import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
    import ExamService from "@/core/services/exam/ExamService";
    import UserService from "@/core/services/user/UserService";
    import AcademicClassStudentService from "@/core/services/academic-class/class-student/AcademicClassStudentService";

    const academicClassStudentService = new AcademicClassStudentService()
    const levelService = new LevelService()
    const academicClassService = new AcademicClassService();
    const programService = new ProgramService();
    const academicYearService = new AcademicYearService();
    const gradeLevelService = new GradeLevelService();
    const examService = new ExamService();
    const userService = new UserService();
    export default {
        name: "Attendence",

        components: {
        },
        data() {
            return {
                search: {
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: '',
                    type: 'student',
                    limit: 250,

                },
                selectedStudents: [],

                academic_class: {
                    academic_year_id: null,
                    level_id: null,
                    program_id: null,
                    grade_id: null,
                    shift: null,
                    title: null,
                    is_active: true
                },
                date: new Date().toISOString().substr(0, 10),
                dialog: false,
                menu: false,
                edit: false,
                menu2: false,
                users: [],
                programs: [],
                academicYears: [],
                courses: [],
                classes: [],
                class_students: [],
                academic_id: null,
                page: null,
                total: null,
                perPage: null,
                tab: null,
            };
        },
        mounted() {
            this.getAssignedStudents();
            this.getClass();
        },
        computed: {
            classId() {
                return this.$route.params.classId;
            },
            today(){
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = today.getFullYear();

                today = mm + '/' + dd + '/' + yyyy;
                return today;
            },
        },
        methods: {

            showModal(academic_class = {}) {
                if (academic_class.id) {

                    this.edit = true
                    this.dialog = true
                    this.academic_class = academic_class;
                    this.getAssignedStudents();
                    this.getStudents();

                }
            },
            hideModal() {
                this.teacher = []
                this.dialog = false;
            },
            toggleModal() {
                // We pass the ID of the button that we want to return focus to
                // when the modal has hidden
                this.dialog = !dialog;
            },
            getClass(){
                academicClassService.show(this.classId).then((res)=>{
                    this.academic_class = res.data.class;
                })
            },
            getStudents() {
                userService.getStudentsByAcademicYearProgramAndSemester(this.academic_class.academic_year_id, this.academic_class.program_id, this.academic_class.grade_id).then(response => {
                    this.users = response.data.data;

                });
            },

            getAssignedStudents() {
                academicClassService.getAssignedStudents(this.classId).then(response => {
                    this.selectedStudents = response.data.students;
                });
            },

            save() {
                this.selectedStudents.forEach((user) => {
                    this.class_students.push({
                        class_id: this.academic_class.id,
                        student_id: user.id,
                    })
                })
                academicClassService.syncStudents(this.academic_class.id, this.class_students).then((response) => {
                    this.resetForm()
                    this.$snotify.success("Information updated");
                })

            },

            resetForm() {
                this.selectedStudents = [];
                this.class_students = [];
                this.academic_class = {
                    level_id: null,
                    program_id: null,
                    grade_id: null,
                    shift: null,
                    title: null,
                    is_active: true
                }
                this.hideModal();

            }
        }
    };
</script>
